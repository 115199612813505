 /* Frontend css */

 /* Header css start */
 .header {
    /* display: flex; */
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}
.header img.logo-image {
    width: 85px;
}
.header-right-sec {
    padding-right: 10px;
    display: flex;
    align-items: center;
}
.header .logo-lg{
    width: 258px;
    background: #998d8d;
    text-align: center;
}
img.profile-image {
    width: 50px;
    border-radius: 50%;
}
 /* Header css end */

/* Sidebar css start */
.sidebar {
    width: 200px;
}   
.sidebar ul li {
    list-style: none;
    padding: 6px;
}
.navbar-brand{
    margin-right: 0 !important;
}
.sidebar .profile {
    height: 100px;
    text-align: center;
    width: 250px;
}
.sidebar ul {
    padding-left: 15px;
}
.sidebar .profile-image {
    width: 50px;
    margin-top: 15px;
    border-radius: 50%;
}
/* Sidebar css end */

/* dashboard css start */
.dashboard{
    width: 83%;
    float: right;
    min-height: 644px;
    background-color: #ebeff2;
    padding: 1.6em;
}
/* dashboard css end */


@media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  -webkit-animation: App-logo-spin infinite 20s linear;
	          animation: App-logo-spin infinite 20s linear;
	}
}
@-webkit-keyframes App-logo-spin {
	from {
	  	-webkit-transform: rotate(0deg);
	  	        transform: rotate(0deg);
	}
	to {
	  	-webkit-transform: rotate(360deg);
	  	        transform: rotate(360deg);
	}
}
@keyframes App-logo-spin {
	from {
	  	-webkit-transform: rotate(0deg);
	  	        transform: rotate(0deg);
	}
	to {
	  	-webkit-transform: rotate(360deg);
	  	        transform: rotate(360deg);
	}
}
.App {
    text-align: center;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.App-header {
  	background-color: #282c34;
  	min-height: 100vh;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: center;
  	font-size: calc(10px + 2vmin);
  	color: white;
}
.App-link {
  	color: #61dafb;
}
a{
  	text-decoration: none !important;
}
.border-unset {
    border: unset;
}
.bg-unset {
    background: unset;
}
.rc-tabs{
	overflow: unset !important;
}


/* For My Prodile Page CSS Start*/
.my-profile .profile-heading{
	border-bottom:1px solid #ddd;
}
.my-profile #image{
	opacity: 0;
	width:0;
}
.my-profile #company{
	opacity: 0;
	width:0;
}
.my-profile img.profile-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    /* border: 1px solid #ddd; */
    margin-left: 8px;
    object-fit: cover;
}
.my-profile .camera-icon {
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 50%;
	font-size: 30px;
}
.dp_box{position: relative;}
.dp_box label .overlay{display: none;justify-content: center;align-items: center;position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-74px, -78px);transform: translate(-74px, -78px);width: 149px;height: 149px;border-radius: 50%;background-color: rgba(0, 0, 0, 0.5);margin-left: 4px;}
.dp_box:hover label .overlay{display: flex;}
.dp_box:hover label .overlay .btn.camera{margin-right: 50px;}
.dp_box:hover label .overlay .btn, .dp_box:hover label .overlay .btn:focus, .dp_box:hover label .overlay .btn:active{outline: 0;border: 0;}
.dp_box:hover label .overlay .btn .fa{color: #FFF;font-size: 22px;}
.dp_box:hover label .overlay .btn:hover .fa{color: #CCC;}

.dp_box .icon_box{display: none;justify-content: center;align-items: center;position: absolute;top: 50%;left: 50%;-webkit-transform: translate(60%, -70%);transform: translate(60%, -70%);z-index: 22;width: 22px;height: 22px;}
.dp_box:hover .icon_box{display: flex;}
.dp_box .icon_box .btn, .dp_box .icon_box .btn:focus, .dp_box .icon_box .btn:active{outline: 0;border: 0;}
.dp_box .icon_box .btn .fa{color: #FFF;font-size: 22px;}
.dp_box .icon_box .btn:hover .fa{color: #CCC;}

/* For My Prodile Page CSS End*/

/* ImportProperties */
.over_table{overflow: scroll;max-height: 360px;width: 100%;box-sizing: border-box;scroll-behavior: smooth;scrollbar-color: #A8A8A8 #F1F1F1;scrollbar-width: thin;}
.over_table::-webkit-scrollbar {width: 7px;height: 7px;}
.over_table::-webkit-scrollbar-track {background-color: #F1F1F1;}
.over_table::-webkit-scrollbar-thumb {background: #A8A8A8;}
.over_table table {width: 100%;background-color: #ffffff;border-collapse: collapse;color: #000000;}
.over_table table td, .over_table table th {padding: 5px;white-space: nowrap;}
.over_table table thead {position: -webkit-sticky;position: sticky;inset-block-start: 0;background-color: #dee2e6;}
/* ImportProperties */

/* overnot in my Properties and Demands */
.overnot .rdt_TableHeader + div{min-height: 356px;background-color: #FFF;}
.overnot .rdt_TableHeader + div .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{position: -webkit-sticky;position: sticky;right: 0;background-color: #FFF;z-index: 55;}
.overnot .rdt_TableHeader + div .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {position: -webkit-sticky;position: sticky;right: 0;background-color: #FFF;z-index: 55;}
.overnot .rdt_TableHeader + div .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child .dropdown .dropdown-menu{position: absolute !important;-webkit-transform: translate(-68px, 0px) !important;transform: translate(-68px, 0px) !important;z-index: 55555;}



.right_info h3{display: block;}
.right_info .card{margin-bottom: 20px;}
.right_info .imgbox{display: block;width: auto;}
.right_info .imgbox img{max-width: 60px;width: 60px;height: 60px;object-fit: cover;object-position: center;border-radius: 6px;}
.right_info .contbox{display: block;width: 65%;}

.damandwrap{overflow-x: scroll;}
.damandwrap table.DamandInfoTable {width: 100%;color: #000000;table-layout: fixed;}
.damandwrap table.DamandInfoTable td,.damandwrap table.DamandInfoTable th {padding: 5px;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.damandwrap table.DamandInfoTable thead {border-bottom: 1px solid #0000001f;}

/* RC TABS START */

.rc-tabs {
  border: none !important;
}
.rc-tabs-nav{
  min-width: 170px !important;
}
.rc-tabs-tab {
  padding: 5px;
  text-transform: capitalize;
}
.rc-tabs-tab .rc-tabs-tab-btn {
  font-weight: normal;
  font-size: 15px;
}
.rc-tabs-tab.rc-tabs-tab-active {
  background: ececec;
  color: #00a6f8;
}
.rc-tabs-ink-bar {
  background: #59c7fd !important;
}
.rc-tabs-content-holder {
  padding: 10px;
  background: #ececec;
}
.rc-tabs-content-holder{
  border: 1px solid #ddd;
}
.alert{
  padding: 10px 10px !important;
}
.alert.error{
	padding: 0;
    font-size: 12px;
    margin: 0;
    color: red;
    padding: 0 !important;
}
/* RC TABS STOP */

.top-right-radius-25{
	border-top-right-radius:25px;
}

.bottom-right-radius-25{
	border-bottom-right-radius:25px;
}

.top-left-radius-25{
	border-top-left-radius:25px;
}
.bottom-left-radius-25{
	border-bottom-left-radius:25px;
}

.top-bottom-right-radius{	
	border-top-right-radius:100px;
	border-bottom-right-radius:100px;
}

.border-radius-25{
	border-radius: 25px;
}

.font-weight-500{
	font-weight:500;
}
.font-weight-400{
	font-weight:400;
}

.text-ascent{
	color: #10f3c0;
}

.my-button{
	border-radius: 50px;
	font-weight:500;
	color: white;
}

.custom-input>input{
	border: 1px solid #ddd;
	padding: 5px 10px;
}

html, body, #root{
	height: 100%;
}

.property-features{
	background: #1b2e4a;
    color: white;
    padding: 5px 8px;
    border-radius: 10px;
	font-weight: 500;
}

@media (min-width: 991.98px) { 
  .on-desktop{
    display: block !important;
  }  
  .on-mobile{
    display: none !important;
  }
}
@media (max-width: 991.98px) {  
  .on-mobile{
    display: block !important;
  }

  .on-desktop{
    display: none !important;
  }    
}

.nav-link{
	color: #1b2e4a;
}

.bg-info{
	background-color: #f1f1f1 !important;
}

.card-box{
	background-color: #E6E6E6;
}
.first-box {
    background: #fff;
    padding: 70px 100px;
    
}
.second-box {
    padding: 20px 30px;
}
.big-box img{
	border-radius: 10px;
	width: 100%;
}
.nav-tabs .nav-item{
	width: 33% !important;
}
.tab-content{
	padding: 0;
}
.nav-tabs .nav-item{
	margin-bottom: unset;
}
.nav-tabs .nav-link{
	border: unset;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
	border-bottom: 2px solid #3aff3a !important;
}
.tab-pane img, .w-20{
	width: 20px;
}
.card{
	border: 1px solid #000 !important;
	background-color: unset !important;
	border-radius: unset !important;
}
.box {
    border: 1px solid #000;
    height: 90px;
    /* padding: 20px; */
    text-align: center;
    background: white;
    width: 100px;
}
img.box-image {
    width: 100%;
}
.price-sec {
    background: #fff;
    padding: 5px 10px;
}
.diff-price-sec {
    background: #fff;
    padding: 5px 10px 0px 10px;
}
.h-99{
	height: 99px;
}
.custom-popup_overlay__sUXCC {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
  }
  .custom-popup_popup__2tNqP {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .custom-popup_popup__2tNqP h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .custom-popup_popup__2tNqP .custom-popup_close__3Ncyw {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .custom-popup_popup__2tNqP .custom-popup_close__3Ncyw:hover {
    cursor: pointer;
    color: #000;
  }
  .custom-popup_popup__2tNqP .custom-popup_content__2FgMm {
    max-height: 30%;
    /* overflow: auto; */
  }
  
  @media screen and (max-width: 700px) {
    .custom-popup_popup__2tNqP {
      width: 70%;
    }
  }
  
