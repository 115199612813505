
.card-box{
	background-color: #E6E6E6;
}
.first-box {
    background: #fff;
    padding: 70px 100px;
    
}
.second-box {
    padding: 20px 30px;
}
.big-box img{
	border-radius: 10px;
	width: 100%;
}
.nav-tabs .nav-item{
	width: 33% !important;
}
.tab-content{
	padding: 0;
}
.nav-tabs .nav-item{
	margin-bottom: unset;
}
.nav-tabs .nav-link{
	border: unset;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
	border-bottom: 2px solid #3aff3a !important;
}
.tab-pane img, .w-20{
	width: 20px;
}
.card{
	border: 1px solid #000 !important;
	background-color: unset !important;
	border-radius: unset !important;
}
.box {
    border: 1px solid #000;
    height: 90px;
    /* padding: 20px; */
    text-align: center;
    background: white;
    width: 100px;
}
img.box-image {
    width: 100%;
}
.price-sec {
    background: #fff;
    padding: 5px 10px;
}
.diff-price-sec {
    background: #fff;
    padding: 5px 10px 0px 10px;
}
.h-99{
	height: 99px;
}