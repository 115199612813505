@media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
}
@keyframes App-logo-spin {
	from {
	  	transform: rotate(0deg);
	}
	to {
	  	transform: rotate(360deg);
	}
}
.App {
    text-align: center;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.App-header {
  	background-color: #282c34;
  	min-height: 100vh;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: center;
  	font-size: calc(10px + 2vmin);
  	color: white;
}
.App-link {
  	color: #61dafb;
}
a{
  	text-decoration: none !important;
}
.border-unset {
    border: unset;
}
.bg-unset {
    background: unset;
}
.rc-tabs{
	overflow: unset !important;
}


/* For My Prodile Page CSS Start*/
.my-profile .profile-heading{
	border-bottom:1px solid #ddd;
}
.my-profile #image{
	opacity: 0;
	width:0;
}
.my-profile #company{
	opacity: 0;
	width:0;
}
.my-profile img.profile-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    /* border: 1px solid #ddd; */
    margin-left: 8px;
    object-fit: cover;
}
.my-profile .camera-icon {
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 50%;
	font-size: 30px;
}
.dp_box{position: relative;}
.dp_box label .overlay{display: none;justify-content: center;align-items: center;position: absolute;top: 50%;left: 50%;transform: translate(-74px, -78px);width: 149px;height: 149px;border-radius: 50%;background-color: rgba(0, 0, 0, 0.5);margin-left: 4px;}
.dp_box:hover label .overlay{display: flex;}
.dp_box:hover label .overlay .btn.camera{margin-right: 50px;}
.dp_box:hover label .overlay .btn, .dp_box:hover label .overlay .btn:focus, .dp_box:hover label .overlay .btn:active{outline: 0;border: 0;}
.dp_box:hover label .overlay .btn .fa{color: #FFF;font-size: 22px;}
.dp_box:hover label .overlay .btn:hover .fa{color: #CCC;}

.dp_box .icon_box{display: none;justify-content: center;align-items: center;position: absolute;top: 50%;left: 50%;transform: translate(60%, -70%);z-index: 22;width: 22px;height: 22px;}
.dp_box:hover .icon_box{display: flex;}
.dp_box .icon_box .btn, .dp_box .icon_box .btn:focus, .dp_box .icon_box .btn:active{outline: 0;border: 0;}
.dp_box .icon_box .btn .fa{color: #FFF;font-size: 22px;}
.dp_box .icon_box .btn:hover .fa{color: #CCC;}

/* For My Prodile Page CSS End*/

/* ImportProperties */
.over_table{overflow: scroll;max-height: 360px;width: 100%;-webkit-box-sizing: border-box;box-sizing: border-box;scroll-behavior: smooth;scrollbar-color: #A8A8A8 #F1F1F1;scrollbar-width: thin;}
.over_table::-webkit-scrollbar {width: 7px;height: 7px;}
.over_table::-webkit-scrollbar-track {background-color: #F1F1F1;}
.over_table::-webkit-scrollbar-thumb {background: #A8A8A8;}
.over_table table {width: 100%;background-color: #ffffff;border-collapse: collapse;color: #000000;}
.over_table table td, .over_table table th {padding: 5px;white-space: nowrap;}
.over_table table thead {position: sticky;inset-block-start: 0;background-color: #dee2e6;}
/* ImportProperties */

/* overnot in my Properties and Demands */
.overnot .rdt_TableHeader + div{min-height: 356px;background-color: #FFF;}
.overnot .rdt_TableHeader + div .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{position: sticky;right: 0;background-color: #FFF;z-index: 55;}
.overnot .rdt_TableHeader + div .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {position: sticky;right: 0;background-color: #FFF;z-index: 55;}
.overnot .rdt_TableHeader + div .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child .dropdown .dropdown-menu{position: absolute !important;transform: translate(-68px, 0px) !important;z-index: 55555;}



.right_info h3{display: block;}
.right_info .card{margin-bottom: 20px;}
.right_info .imgbox{display: block;width: auto;}
.right_info .imgbox img{max-width: 60px;width: 60px;height: 60px;object-fit: cover;object-position: center;border-radius: 6px;}
.right_info .contbox{display: block;width: 65%;}

.damandwrap{overflow-x: scroll;}
.damandwrap table.DamandInfoTable {width: 100%;color: #000000;table-layout: fixed;}
.damandwrap table.DamandInfoTable td,.damandwrap table.DamandInfoTable th {padding: 5px;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.damandwrap table.DamandInfoTable thead {border-bottom: 1px solid #0000001f;}

/* RC TABS START */

.rc-tabs {
  border: none !important;
}
.rc-tabs-nav{
  min-width: 170px !important;
}
.rc-tabs-tab {
  padding: 5px;
  text-transform: capitalize;
}
.rc-tabs-tab .rc-tabs-tab-btn {
  font-weight: normal;
  font-size: 15px;
}
.rc-tabs-tab.rc-tabs-tab-active {
  background: ececec;
  color: #00a6f8;
}
.rc-tabs-ink-bar {
  background: #59c7fd !important;
}
.rc-tabs-content-holder {
  padding: 10px;
  background: #ececec;
}
.rc-tabs-content-holder{
  border: 1px solid #ddd;
}
.alert{
  padding: 10px 10px !important;
}
.alert.error{
	padding: 0;
    font-size: 12px;
    margin: 0;
    color: red;
    padding: 0 !important;
}
/* RC TABS STOP */
