.top-right-radius-25{
	border-top-right-radius:25px;
}

.bottom-right-radius-25{
	border-bottom-right-radius:25px;
}

.top-left-radius-25{
	border-top-left-radius:25px;
}
.bottom-left-radius-25{
	border-bottom-left-radius:25px;
}

.top-bottom-right-radius{	
	border-top-right-radius:100px;
	border-bottom-right-radius:100px;
}

.border-radius-25{
	border-radius: 25px;
}

.font-weight-500{
	font-weight:500;
}
.font-weight-400{
	font-weight:400;
}

.text-ascent{
	color: #10f3c0;
}

.my-button{
	border-radius: 50px;
	font-weight:500;
	color: white;
}

.custom-input>input{
	border: 1px solid #ddd;
	padding: 5px 10px;
}

html, body, #root{
	height: 100%;
}

.property-features{
	background: #1b2e4a;
    color: white;
    padding: 5px 8px;
    border-radius: 10px;
	font-weight: 500;
}

@media (min-width: 991.98px) { 
  .on-desktop{
    display: block !important;
  }  
  .on-mobile{
    display: none !important;
  }
}
@media (max-width: 991.98px) {  
  .on-mobile{
    display: block !important;
  }

  .on-desktop{
    display: none !important;
  }    
}

.nav-link{
	color: #1b2e4a;
}

.bg-info{
	background-color: #f1f1f1 !important;
}