 /* Frontend css */

 /* Header css start */
 .header {
    /* display: flex; */
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}
.header img.logo-image {
    width: 85px;
}
.header-right-sec {
    padding-right: 10px;
    display: flex;
    align-items: center;
}
.header .logo-lg{
    width: 258px;
    background: #998d8d;
    text-align: center;
}
img.profile-image {
    width: 50px;
    border-radius: 50%;
}
 /* Header css end */

/* Sidebar css start */
.sidebar {
    width: 200px;
}   
.sidebar ul li {
    list-style: none;
    padding: 6px;
}
.navbar-brand{
    margin-right: 0 !important;
}
.sidebar .profile {
    height: 100px;
    text-align: center;
    width: 250px;
}
.sidebar ul {
    padding-left: 15px;
}
.sidebar .profile-image {
    width: 50px;
    margin-top: 15px;
    border-radius: 50%;
}
/* Sidebar css end */

/* dashboard css start */
.dashboard{
    width: 83%;
    float: right;
    min-height: 644px;
    background-color: #ebeff2;
    padding: 1.6em;
}
/* dashboard css end */

